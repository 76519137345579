<template>
  <div>
    <b-modal
      id="cash-numpad"
      ref="numpad"
      no-close-on-backdrop
      hide-header-close
      @show="whenOpen()"
    >
      <b-input-group size="lg" class="text-light scaleDisplay w-100">
        <template
          v-if="isCashPayment === true"
          #prepend
          style="font-size: 30px"
        >
          <b-input-group-text> $ </b-input-group-text>
        </template>

        <b-form-input
          id="amountInput"
          v-model="numpadValue"
          style="font-size: 30px"
          type="text"
        />

        <template
          v-if="isCashPayment === false"
          #append
          style="font-size: 30px"
        >
          <b-input-group-text> Unidades </b-input-group-text>
        </template>
      </b-input-group>

      <div class="numpad">
        <div
          v-for="n in 9"
          :key="`btn-${n}`"
          v-ripple
          class="btn"
          @click="typeNum(`${n}`)"
        >
          {{ n }}
        </div>
        <div v-ripple class="btn" @click="typeDot('.')">.</div>
        <div v-ripple class="btn" @click="typeNum('0')">0</div>
        <div v-ripple class="btn" @click="delNum()">
          <i class="fas fa-backspace" />
        </div>
      </div>
      <template #modal-footer>
        <div>
          <b-button
            class="mr-1"
            variant="outline-danger"
            @click="cancelPayment()"
          >
            Cancelar
          </b-button>
          <b-button
            :disabled="isCashPayment === true ? numpadValue < cartTotal : null"
            variant="success"
            @click="setValue(numpadValue)"
          >
            Continuar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { mapGetters, mapMutations } from "vuex";

export default {
  directives: {
    Ripple,
  },
  props: {
    isWeight: {
      type: Boolean,
      default: false,
    },
    isCashPayment: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      numpadValue: "",
    };
  },
  computed: {
    ...mapGetters("pos", ["cartTotal"]),
  },
  destroyed() {
    window.removeEventListener("keypress", this.setFocusElement);
  },
  methods: {
    setFocusElement() {
      document.getElementById("amountInput").focus();
    },
    ...mapMutations("pos", ["SET_CASHPAYMENT_MODE"]),
    cancelPayment() {
      this.$bvModal.hide("cash-numpad");
      this.SET_CASHPAYMENT_MODE(false);
    },
    setValue(value) {
      this.$emit("ok", value);
      this.numpadValue = "";
    },
    whenOpen() {
      window.addEventListener("keypress", this.setFocusElement);
    },
    typeNum(val) {
      // this.numpadValue = Number(this.numpadValue + val)
      if (this.numpadValue.includes(".")) {
        const dotPosition = this.numpadValue.split(".");
        if (dotPosition[1].length <= 2) {
          this.numpadValue += val;
        }
      } else if (val === "0" && this.numpadValue === "") {
        this.numpadValue = "0.";
      } else {
        this.numpadValue += val;
      }
    },
    delNum() {
      this.numpadValue = this.numpadValue.slice(0, -1);
    },
    typeDot() {
      if (!this.numpadValue.includes(".")) {
        if (this.numpadValue === "") {
          this.numpadValue += "0.";
        } else {
          this.numpadValue += ".";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.numpad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  .btn {
    font-size: 2rem;
    font-weight: 800;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(white, 0.02);
    border-radius: 10px;
  }
}
.scaleDisplay {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // background: rgba(white, 0.02);
  // border-radius: 10px;
  // padding: .5rem;
  // margin-bottom: 1rem;
  overflow: hidden;
}
</style>
